@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #e7e7e7;
  transition: box-shadow 0.05s ease-out, width 0.1s ease-out, left 0.1s ease-out,
    padding 0.1s ease-out;
  background-color: #ffffff;
  padding: 24px;
  gap: 12px;
  color: var(--colorGrey700);

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    padding: 24px 24px 24px 0;
  }
}

.google {
  color: #ffbb33 !important;
}
.meta {
  color: #3b5998 !important;
}
.amazon {
  color: #ff7f11 !important;
}
.tiktok {
  color: #8a3ffc !important;
}
.directEmail {
  color: #d32f2f !important;
}
.emailNewsletter {
  color: #28a745 !important;
}

.aspectRatioWrapper {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  background-color: #e7e7e7;
  border-radius: 8px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-self: flex-start;

  @media (--viewportMedium) {
    min-width: 180px;
    min-height: 180px;
    max-width: 180px;
    max-height: 180px;
    flex: 1;
    align-self: center;
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  @media (--viewportMedium) {
    width: 70%;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4px;
  gap: 12px;
  padding: 14px 0 2px 0;
  font-size: 13px;
  line-height: 1.2;
  @media (--viewportMedium) {
    flex: 1;
  }
}

.secondaryInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.secondaryInfo1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 40;
}

.secondaryInfo2 {
  font-size: 13px;
  line-height: 1.2;
  flex: 60;
  @media (--viewportMedium) {
    padding-left: 16px;
  }

  & div {
    margin: 0 0 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & .heading {
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.category {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  margin: 2px 0;
}

.adSlots {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  margin: 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 3px 0 12px 0;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  padding-top: 4px;
  display: flex;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: flex-end;
    width: 30%;
    margin-top: 0;
  }
}

.button {
  composes: buttonPrimary from global;
  padding: 0px;
  min-height: 48px;
  width: 100%;
  background-color: var(--marketplaceColor);
  &:hover {
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    min-height: 36px;
    min-width: 72px;
    max-width: 72px;
  }
}

.timeRemainingToCloseListing {
  margin: 0 12px;
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 12px;
  }
}

.countdown {
  color: var(--colorFail);
}
