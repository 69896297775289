@import '../../styles/customMediaQueries.css';

.subscriptionSection p {
  line-height: 1.2;
  margin: 0;
}

.subscriptions {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  /* background: var(--colorWhite); */
  border-collapse: collapse;
  width: 100%;
  gap: 24px;
  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: var(--contentMaxWidthPages);
  }
}

.currentPlan {
  box-shadow: var(--boxShadowPopupLight);
  border: 1px solid var(--marketplaceColor) !important;
}

.currentPlanText {
  color: var(--marketplaceColor);
  margin: 8px 0;
}

.plan {
  display: flex;
  flex-direction: column;
  color: #919f9f;
  padding: 16px;
  border: 0.5px solid var(--colorGrey100);
  border-collapse: collapse;
  width: 100%;

  @media (--viewportMedium) {
    padding: 36px;
    max-width: 25%;
    /* margin: 0 auto; */
  }

  & .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .heading {
      font-size: 24px;
      margin-bottom: 18px 0;
      text-align: center;
      color: var(--marketplaceSecondaryColor);
      align-self: flex-start;
      display: flex;
      align-items: baseline;
      position: relative;
      & .currentPlanCheck {
        & svg {
          margin-right: 16px;
          width: 24px;
          height: 24px;
          & circle {
            fill: var(--colorSuccess);
          }
        }
      }
    }
    & .duration {
      font-size: 24px;
      margin: 8px 0 48px 0;
      color: var(--colorSuccess);
      font-weight: bold;
    }
  }

  & .price {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid var(--matterColor);
    border-bottom: 1px solid var(--matterColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 18px 0;
    text-align: left;
    line-height: 1.2;
  
    & .priceWrapper {
      display: flex;
      flex-direction: row;
      margin-right: 12px;
      color: var(--colorGrey700);
      word-break: break-word;
    }
  
    & .perMonth {
      font-size: 13px;
      font-weight: normal;
    }
  
    & .period {
      font-size: 13px;
      font-weight: normal;
      white-space: pre-wrap;
    }
  
    & .currency {
      font-weight: normal;
    }
  }

  & .details {
    margin-bottom: 48px;
    height: 100%;
  
    & div {
      font-size: 1rem;
      padding: 8px 24px 8px 24px;
      text-indent: -24px;
      text-align: left;
      line-height: 1.2 !important;
      &:before {
        content: "•";
        color: var(--colorSuccess);
        font-weight: 700;
        font-size: 21px;
        margin-right: 8px;
      }
    }
  }

  & .referral {
    height: 100%;
    text-align: left;
    & div {
      font-size: 1rem;
      padding: 8px 24px 8px 24px;
      text-indent: -24px;
      line-height: 1.2 !important;
  
      & svg {
        padding-right: 6px;
        width: 20px;
        height: 20px;
        fill: var(--matterColor);
        & circle {
          fill: var(--colorSuccess);
        }
      }
    }
  
    & .referralHeading {
      margin: 0;
      font-size: 16px;
      color: var(--colorGrey700);
    }
  }

  & .bonus {
    font-size: 24px;
    font-weight: bold;
    color: var(--colorGrey700);
  }
  
  & .bonusCurrency {
    font-size: 24px;
    font-weight: bold;
    color: var(--colorGrey700);
  }
  
  & .bonusPeriod {
    font-size: 16px;
    margin-left: 12px;
  }

  & .note {
    padding: 12px 8px;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    & .highlight {
    }
  }

  & .ctaButton {
    background: var(--colorSuccess);
    color: var(--colorWhite);
  }

  & hr {
    border-top: 1px solid var(--colorGrey100);
    width: 100%;
    margin: 0;
  }
}

.badge {
  font-size: 0.875rem;
  text-align: center;
  background: black;
  color: var(--marketplaceColor);
  text-transform: uppercase;
  margin: 1.5rem;
  padding: 1px;
  font-weight: bold;
}

.terms {
  font-size: 13px;
  text-align: center;
  margin: 0.5rem 0;
}

.ctaContainer {
  padding: 24px 16px 24px 16px;
}

.ctaButton {
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    background-color: var(--colorGrey100) !important;
    color: var(--colorGrey400) !important;
  }
}

.error {
  color: var(--colorFail);
  margin: 24px 0 0 4px;
}
