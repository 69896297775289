.root {
  width: 24px;
  height: 24px;
}

.circle {
  fill: var(--marketplaceColor);
}

.path {
  fill: var(--colorWhite);
}
